import React, { useEffect, useRef } from "react";
import useAllPosts from "../../../hooks/use-all-posts";
import { jsx, Heading, Flex, Box } from "theme-ui";
import Layout from "../../../components/layout";
import Seo from "../../../components/seo";
import { Data, Edge, Network, Options } from "vis-network";
import { DataSet } from "vis-data";

type Post = {
  id: string;
  title: string;
  description: string;
  date: string;
  tags: string[];
};

type Props = {
  [key: string]: any;
};

export default function MinimalBlogCoreGraph({ ...props }: Props) {
  const graphRef = useRef<HTMLDivElement>(null);
  const networkRef = useRef<Network | null>(null);
  const posts = useAllPosts();

  useEffect(() => {
    const processData = () => {
      try {
        const nodes = new DataSet(
          posts.map((blog) => ({
            id: blog.link,
            label: blog.title,
            title: `${blog.title}\nDate: ${blog.date}\nTags: ${blog.tags.join(
              ", "
            )}`,
          }))
        );

        const edges = new DataSet();

        posts.forEach((blog) => {
          blog.internalLinks.forEach((linkedUrl) => {
            const targetNode = posts.find((b) => b.link === linkedUrl);
            if (targetNode && targetNode.link !== blog.link) {
              edges.add({
                from: blog.link,
                to: targetNode.link,
                title: linkedUrl,
              } as Edge);
            }
          });
        });

        if (graphRef.current) {
          if (networkRef.current) {
            networkRef.current.destroy();
          }

          const data = { nodes, edges } as Data;
          const options = {
            backgroundColor: "#1a1a1a",
            nodes: {
              shape: "dot",
              size: 10,
              font: {
                size: 12,
                color: "#ffffff",
                face: "Arial",
              },
              color: {
                background: "#808080",
                border: "#808080",
                highlight: {
                  background: "#a0a0a0",
                  border: "#a0a0a0",
                },
              },
              borderWidth: 1,
            },
            edges: {
              width: 0.5,
              color: { color: "#606060", highlight: "#808080" },
              smooth: {
                type: "continuous",
              },
            },
            physics: {
              stabilization: true,
              barnesHut: {
                gravitationalConstant: -5000,
                centralGravity: 0.5,
                springLength: 150,
                springConstant: 0.04,
                damping: 0.09,
                avoidOverlap: 0.1,
              },
              minVelocity: 0.75,
            },
            zoom: {
              scale: 4, // Initial zoom level
              min: 0.2, // Minimum zoom level (20%)
              max: 4, // Maximum zoom level (200%)
            },
            interaction: {
              // zoomView: true,
              // navigationButtons: true,
              zoomSpeed: 0.1, // Slows down the zoom speed
              // minZoom: 1.5, // Matches the min zoom level
              // maxZoom: 1, // Matches the max zoom level
              zoomView: true,
              navigationButtons: true,
              keyboard: true,
              minZoom: 0.2,
              maxZoom: 4,
            },
          };

          networkRef.current = new Network(
            graphRef.current,
            data,
            options as unknown as Options
          );

          networkRef.current.on("selectNode", (params) => {
            const selectedNode = nodes.get(params.nodes[0]);
            console.log("Selected node:", selectedNode);
          });
        }
      } catch (error) {
        console.error("Error processing blog data:", error);
      }
    };

    processData();

    return () => {
      if (networkRef.current) {
        networkRef.current.destroy();
      }
    };
  }, [posts]);

  console.log(posts);

  return (
    <Layout>
      <Seo title="Blog Graph" />
      <Flex
        sx={{
          alignItems: `center`,
          // flexDirection: "column",
          justifyContent: `space-between`,
          flexFlow: `wrap`,
        }}
      >
        <Heading as="h1" variant="styles.h1" sx={{ marginY: 2 }}>
          Blog Graph
        </Heading>
      </Flex>
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: ["50vh", "60vh", "70vh"],
          borderRadius: "8px",
          overflow: "hidden",
        }}
      >
        <div
          ref={graphRef}
          style={{
            flex: 1,
            width: "100%",
            height: "100%",
          }}
        />
      </Box>
    </Layout>
  );
}
